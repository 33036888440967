<template>
  <div>
    <div>
      <div class="mb-4">
        <b-form inline>
          <div class="d-none d-md-block">
            <b-icon icon="filter" font-scale="1.5" variant="primary"></b-icon>
          </div>

          <div class="form-group" v-if="eventOptions && eventOptions.length > 0">
            <label class="ml-md-5 mr-md-3">Event</label>
            <b-form-select
              v-model="eventSelected"
              :options="eventOptions"
            ></b-form-select>
          </div>
          <div class="form-group">
            <label class="ml-md-5 mr-md-3">Date</label>

            <b-form-datepicker
              v-model="dateSelected"
              placeholder="Chọn ngày"
              locale="en"
            ></b-form-datepicker>
          </div>
          <div class="form-group">
            <label class="ml-md-5 mr-md-3">Time</label>

            <b-form-timepicker
              v-model="fromHourSelected"
              placeholder="Bắt đầu"
              label-close-button="Đóng"
            ></b-form-timepicker>
            <span class="px-2">-</span>
            <b-form-timepicker
              v-model="toHourSelected"
              placeholder="Kết thúc"
              label-close-button="Đóng"
            ></b-form-timepicker>
          </div>

          <div class="form-group">
            <b-button class="ml-md-4" variant="primary" @click="getEventFiles">
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>
        </b-form>
      </div>
      <div v-if="!loading">
        <div v-if="eventFiles.length == 0" class="alert alert-danger">
          No data available at selected time
        </div>
        <div class="row no-gutters" v-else-if="eventFiles.length > 0">
          <div class="col-md-8">
            <vod-player
              v-if="fileSelected"
              :url="fileSelected.url"
              id="mainplayer"
            ></vod-player>
          </div>
          <div class="col-md-4">
            <div class="file-list">
              <h2>Search results</h2>

              <div
                v-for="(item, idx) in eventFiles"
                :key="idx"
                :class="
                  fileSelected && fileSelected.id == item.id
                    ? 'file-item active'
                    : 'file-item'
                "
                @click="fileSelected = item"
              >
                <b-icon
                  :icon="
                    fileSelected && fileSelected.id == item.id
                      ? 'pause-circle-fill'
                      : 'play-circle-fill'
                  "
                  scale="1.5"
                ></b-icon>
                <span> {{ item.name }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventApi from "@/api/eventApi";
import VodPlayer from "@/components/VodPlayer.vue";

export default {
  data() {
    return {
      eventHasVods: [],
      eventFiles: [],
      loading: false,
      dateSelected: "",
      fromHourSelected: "00:00",
      toHourSelected: "23:59",
      eventSelected: 0,
      fileSelected: undefined,
    };
  },
  computed: {
    eventOptions() {
      var res = [];
      this.eventHasVods.forEach(function (val) {
        res.push({ value: val.id, text: val.name });
      });
      return res;
    },
  },
  components: {
    VodPlayer,
  },
  props: {},
  created() {
    const date = new Date();
    this.dateSelected = date.toISOString().slice(0, 10);
    this.getEventHasVods();
  },
  watch: {
    // eventOptions(val) {
    //   this.eventSelected = val[0].value;
    // },
    dateSelected() {
       this.getEventHasVods();
    },
    fromHourSelected() {
      //this.getEventHasVods();
    },
    toHourSelected() {
      //this.getEventHasVods();
    },
  },
  methods: {
    getEventHasVods() {
      this.loading = true;

      this.eventHasVods = [];
      eventApi
        .getEventHasVods(
          this.dateSelected,
          this.fromHourSelected,
          this.toHourSelected
        )
        .then((response) => {
          this.eventHasVods = response;
          this.loading = false;
          if (response.length > 0 && !this.fileSelected) {
            this.eventSelected = response[0].id;
            this.getEventFiles();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getEventFiles() {
      this.loading = true;
      eventApi
        .getEventFiles({
          date: this.dateSelected,
          fromHour: this.fromHourSelected,
          toHour: this.toHourSelected,
          eventId: this.eventSelected,
        })
        .then((response) => {
          console.log("eventFiles", response);
          this.eventFiles = response;
          if (response.length > 0) this.fileSelected = response[0];
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>