<template>
  <div>
    <video ref="vodPlayer" controls autoplay muted playsinline></video>
  </div>
</template>

<script>
import Hls from "hls.js";
// import videojs from "video.js";
// import "@videojs/http-streaming";
export default {
  name: "VodPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    url: String,
  },
  data() {
    return {
      player: null,
      hls: null,
    };
  },
  mounted() {
    
      this.play();
  },
  watch: {
    url: function () {
      this.play();
    },
  },
  beforeDestroy() {
    if (this.hls) this.hls.destroy();
  },
  methods: {
    play() {
      let self = this;

      if (Hls.isSupported()) {
        console.log("hello hls.js!");

        if (self.hls) {
          self.hls.destroy();
          clearInterval(self.hls.bufferTimer);
          self.hls = null;
        }

        var config = {
          //liveSyncDurationCount: 1,
          enableWorker: true,
          maxBufferLength: 1,
          liveBackBufferLength: 0,
          liveSyncDuration: 0,
          liveMaxLatencyDuration: 5,
          liveDurationInfinity: true,
          highBufferWatchdogPeriod: 1,
          debug: true
          //liveDurationInfinity: true,
        };
        var hls = new Hls(config);
        self.hls = hls;
        hls.attachMedia(self.$refs.vodPlayer);
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          console.log("video and hls.js are now bound together !");

          hls.loadSource(self.url);
          hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            console.log(
              "manifest loaded, found " + data.levels.length + " quality level"
            );

            self.$refs["vodPlayer"].play();
          });
          hls.on(Hls.Events.ERROR, function (event, data) {
            var errorType = data.type;
            var errorDetails = data.details;
            var errorFatal = data.fatal;
            console.log("player error", errorType, errorDetails, errorFatal);

            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover"
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
            /*
          switch (data.details) {
            case Hls.ErrorDetails.FRAG_LOAD_ERROR:
              // ....
              break;
            default:
              break;
          }
          */
          });
        });
      } else {
        self.$refs["vodPlayer"].src = self.url;
        self.$refs["vodPlayer"].addEventListener("canplay", function () {
          self.$refs["vodPlayer"].play();
        });
      }
    },
  },
};
</script>
<style type="text/css">
</style>